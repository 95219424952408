

export const ProjectData= [
    {
        id:1,
        name: 'obook.fr',
        Languages: ['React','Symfony','CSS'],
        languagesIcons:['fab fa-react','fab fa-css3-alt','fab fa-symfony'],
        source: 'https://github.com/Ghauspie/Obook.fr',
        info: 'Site API react créer en groupe de 4 suite a la formation d\'oclook. Durant ce projet j\'ai occupé le rôle de scrum master ainsi que celui de developpeur back en symfony.  Site en cours de changement de serveur',
        iframe:'media/obook.jpg',
        prod: 'https://obook.fr',
    },
    {
        id:2,
        name: 'admin.obook.fr',
        Languages: ['Symfony','CSS'],
        languagesIcons:['fab fa-css3-alt','fab fa-symfony'],
        source: 'https://github.com/Ghauspie/admin.obook.fr',
        info: 'Site d\'administration fait uniquement par moi même suite a la mise en prod d\'obook.fr, afin de permettre de continuer a administre obook.fr ainsi qu\'avoir un suivit des hebergement du projet front office et back end. Site en cours de changement de serveur',
        iframe:'media/adminobook.JPG',
        prod: '',//'https://admin.obook.fr',
    },
    {
        id:3,
        name: 'php.ghauspie.fr',
        Languages: ['HTML','PHP','CSS'],
        languagesIcons:['fab fa-php','fab fa-css3-alt','fab fa-html5'],
        source: 'https://github.com/Ghauspie/Php-ghauspie.fr',
        info: 'Mon Premier site C.V fait exclusivement en PHP. Suite a la saison 2 et 3 de ma formation developpeur web chez oclook. Il a etait mis en stand by car je n\'aimé pas le graphisme que j\'ai appliqué sur le site',
        iframe:'http://php.ghauspie.fr',
        prod: 'http://php.ghauspie.fr',
    },
    {
        id:4,
        name: 'ghauspie.fr',
        Languages: ['HTML','React','CSS'],
        languagesIcons:['fab fa-react','fab fa-css3-alt','fab fa-html5'],
        source: 'https://github.com/Ghauspie/Php-ghauspie.fr',
        info: 'Mon Site C.V Portfolio refait avec react ',
        iframe:'media/cv.jpg',
        prod: 'http://ghauspie.fr',
    },
        {
        id:5,
        name: 'Ma journée Type chez Simplon',
        Languages: ['HTML','JS','CSS','JSON'],
        languagesIcons:['fab fa-js-square','fab fa-css3-alt','fab fa-html5'],
        source: 'https://thibaultmontois.github.io/SJDR/public/',
        info: 'Ce projet a été construit durant ma formation .Net chez Simplon. Il est écrit en langage Js et nous étions une équipe de 3. c\'est un projet de type histoire a choix avec une animation d\'un robot fait en div et pour l\'animation cela ce déroule du côté du css. De plus le projet vas passer en code Angular avec C# pour la partie backend',
        iframe:'media/JDR.PNG',
        prod: 'https://thibaultmontois.github.io/SJDR/public/',
    },
    {
        id:6,
        name: 'Squid game: Marbles',
        Languages: ['HTML','JS','CSS','JSON','Typescrit'],
        languagesIcons:['fab fa-js-square','fab fa-css3-alt','fab fa-html5'],
        source: 'https://github.com/Ghauspie/SquidGameS',
        info: 'Ce projet a été construit durant ma formation .Net chez Simplon. Il est écrit en langage typescript et nous étions une équipe de 3',
        iframe:'media/SQM.PNG',
        prod: 'https://squid-game-marbles.test-hauspie.fr/',
    },
    {
        id:7,
        name: 'Popcornmovie',
        Languages: ['HTML','JS','CSS','Api-Web','Angular'],
        languagesIcons:['fab fa-js-square','fab fa-css3-alt','fab fa-html5','fab fa-angular'],
        source: 'https://github.com/Ghauspie/popcornmovie',
        info: 'Ce projet a été construit durant ma formation .Net chez Simplon. Il est avec le framework Angular et nous étions une équipe de 3. Le but de ce projet est de récupérer les informations qu\'une API Web nous retourne afin de les affiches.',
        iframe:'media/PopCornMovie.png',
        prod: '',
    },
    {
        id:8,
        name: 'Tuto-En-Folie',
        Languages: ['HTML','JS','CSS','PHP','Symfony'],
        languagesIcons:['fab fa-js-square','fab fa-css3-alt','fab fa-html5','fab fa-symfony','fab fa-php'],
        source: '',
        info: 'Site regroupant tout les tutos, procedure ou test sur different language et BDD',
        iframe:'media/TEF.PNG',
        prod: '',
    }
]