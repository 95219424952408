export const LanguageCompData = [
    {
        id:1,
        Name:'HTML',
        year:1.2,
    },
    {
        id:2,
        Name:'CSS',
        year:1.2,
    },

    {
        id:3,
        Name:'PHP',
        year:1.2,
    },
    {
        id:4,
        Name:'JS',
        year: 1.2,
    },
 
    {
        id:6,
        Name:'Powershell',
        year:3,
    },
    {
        id:7,
        Name:'Bash',
        year:5,
    },
    {
        id:8,
        Name:'shell',
        year:4,
    },
    {
        id:9,
        Name:'SQL',
        year:5,
    },
    {
        id:10,
        Name:'Cobol',
        year:0.1,
    },
    {
        id:11,
        Name:'C#',
        year:0.1,
    },
];