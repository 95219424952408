export const FrameworkCompData= [
    {
        id:1,
        Name:'React',
        year:0.8,
    },
    {
        id:2,
        Name:'NodeJS',
        year:1.2,
    },
    {
        id:3,
        Name:'Symfony',
        year:0.8,
    },
    {
        id:4,
        Name:'Bulma',
        year:0.3,
    },
    {
        id:5,
        Name:'Bootstrap',
        year:0.5,
    },
    {
        id:6,
        Name:'NextJS',
        year:0.8,
    },
    {
        id:7,
        Name:'Angular',
        year:0.2,
    },
    {
        id:8,
        Name:'TypeScript',
        year:0.2,
    },
    {
        id:9,
        Name:'Prestashop',
        year:0.2,
    },
];