export const ItCompData =[
    {
        id:1,
        Name:'Windows confirmé, Linux intermédiaire et mac débutant',
        
    },
    {
        id:2,
        Name:'VOIP Cisco, VOIP Ascom',
        
    },

    {
        id:4,
        Name:' GLPI',
        
    },
    {
        id:5,
        Name:' IWS',
        
    },
    {
        id:5,
        Name:'AD',
        
    },
    {
        id:6,
        Name:'VMware, Hyper-V',
        
    },
    {
        id:8,
        Name:'SAP',
        
    },
    {
        id:9,
        Name:'Fog',
        
    },
    {
        id:10,
        Name:'SCCM',
        
    },
    {
        id:11,
        Name:'Phpmyadmin, Dbreaver',
        
    },
    {
        id:13,
        Name:'CUCM',
        
    },

]