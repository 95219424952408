export const OtherCompData = [
    {
        id:1,
        Name:'Création de procédures',
        
    },
    {
        id:2,
        Name:'Gestion de projets',
        
    },
    {
        id:3,
        Name:'Relation client',
        
    },
    {
        id:4,
        Name:'Restitution des connaissances',
        
    },
    {
        id:5,
        Name:'Méthode projet agile et scrum',
        
    },
    {
        id:6,
        Name:'travail en équipe',
        
    },



];